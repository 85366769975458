import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"

// Components
import RichTextRenderer from "../../../components/richTextRenderer"

// Stylesheet
import styles from "./index.module.scss"

// Images
import stroke from "../../../images/stroke1.svg"

const options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (
      <h1 className={styles.title}>{children}</h1>
    ),
  },
  renderMark: {
    [MARKS.BOLD]: text => {
      return <span className={styles.highlightedText}>{text}</span>
    },
  },
}

const Banner = ({ data: { title, details, bannerImage }, noStroke }) => (
  <div className={styles.banner}>
    <div className="max-w-6xl mx-auto px-4 xl:px-0 h-full flex flex-col-reverse md:flex-row justify-around md:justify-between items-center">
      <div className="w-full md:w-1/2">
        <div className="hidden md:block">
          <RichTextRenderer content={title.raw} options={options} />
        </div>
        <h1 className={`${styles.title} md:hidden`}>
          A place to <span className={styles.highlightedText}>celebrate</span>
          <br />
          to the greatest Moms.
        </h1>
        <Link to="/create-tribute">
          <button className="btn twine-gradient w-full md:w-auto">
            Create a Tribute
          </button>
        </Link>
        <p className={`${styles.details} hidden md:block`}>{details}</p>
      </div>
      <div className="w-full md:w-1/2">
        <Img fluid={bannerImage.fluid} alt={bannerImage.title} />
      </div>
    </div>

    {noStroke && <img src={stroke} className={styles.stroke} />}
  </div>
)

export default Banner
