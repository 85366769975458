import React from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

// Components
import { HeartIcon } from "../../../components/icons"

// Stylesheet
import styles from "./index.module.scss"

const FeaturedSection = ({
  data: {
    id,
    tributeContent,
    mom: { firstName, lastName, birthState, birthCity, birthDate, image },
  },
}) => {
  const name = `${firstName} ${lastName}`
  const imageStack = [
    image.fluid,
    `linear-gradient(
      237deg,
      rgba(255, 255, 255, 0.82) 0%,
      rgba(255, 255, 255, 0.68) 3%,
      rgba(255, 255, 255, 0) 55%
    )`,
  ].reverse()
  return (
    <div className={styles.featuredSection}>
      <div className="max-w-6xl mx-auto px-4 xl:px-0">
        <div className="flex flex-col-reverse lg:flex-row justify-center items-center">
          <div className={`${styles.featuredImgContainer} w-full lg:w-3/5`}>
            <BackgroundImage fluid={imageStack} className={styles.img} />
            <span className="secondary-font">Love!</span>
          </div>
          <div className="w-full lg:w-2/5 md:pt-12 text-center lg:text-left">
            <h2
              className={`${styles.title} w-3/4 mx-auto md:w-full mb-4 md:mb-8`}
            >
              <span>
                Featured Mom
                <HeartIcon />
              </span>
            </h2>
            <p className={styles.name}>
              <span>{name}</span>, Born in {birthCity}, {`${birthState}`}
            </p>
            <p className={styles.dob}>
              <span>Birthday:</span> {birthDate}
            </p>
            <div
              className={`small hidden lg:block mt-4 mb-8 ${styles.details}`}
              dangerouslySetInnerHTML={{
                __html: `${tributeContent.tributeContent.slice(0, 112)}...`,
              }}
            />
            <Link
              to={`/tribute/${name.split(" ").join("-").toLowerCase()}/${id}`}
            >
              <button className="hidden lg:block btn twine-gradient">
                View {name}’s Tribute
              </button>
            </Link>
          </div>
        </div>
        <div
          className={`lg:hidden small mt-4 mb-8 ${styles.details}`}
          dangerouslySetInnerHTML={{
            __html: `${tributeContent.tributeContent.slice(0, 112)}...`,
          }}
        />
        <button className="lg:hidden w-full btn twine-gradient">
          View {name}’s Tribute
        </button>
      </div>
    </div>
  )
}

export default FeaturedSection
