import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import moment from "moment-timezone"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Containers
import { Banner, Featured, Tributes, Articles } from "../containers/home"

const IndexPage = ({ data }) => {
  const {
    ogtitle,
    ogdescription,
    title,
    details,
    bannerImage,
    tributeSectionTitle,
    tributeSectionDetails,
    articlesSectionTitle,
    articlesList: articles,
  } = data.home
  const [featuredTribute, setFeaturedTribute] = useState(null)
  const tributesList = data.tributes.edges
  const articlesList = articles.map(article => {
    return { node: { ...article } }
  })
  const { edges: featuredTributes } = data.featuredTributes
  const bannerData = { title, details, bannerImage }
  const tributesSectionData = {
    title: tributeSectionTitle,
    details: tributeSectionDetails,
    data: tributesList,
  }
  const articleSectionData = { title: articlesSectionTitle, articlesList }
  // console.log("featured tributes", featuredTributes)

  // Get PST timezone date/month
  const pstDate = moment().tz("America/Los_Angeles").date()
  const pstMonth = moment().tz("America/Los_Angeles").month() + 1 // Moment months are indexed from 0-11
  const pstYear = moment().tz("America/Los_Angeles").year()
  const hr = moment().tz("America/Los_Angeles")
  console.log("America/Los_Angeles hour", hr.format())

  const featured = featuredTributes.filter((tribute, index) => {
    if (tribute.node.featured) {
      const featureDate = moment(tribute.node.featuredDate).tz(
        "America/Los_Angeles",
        true
      )

      const date = featureDate.date()
      const month = featureDate.month() + 1
      const year = featureDate.year()

      return date === pstDate && month === pstMonth && year === pstYear
    }
  })

  if (featured.length > 0) {
    const date = new Date(featured[0].node.featuredDate).getDate()
    const month = new Date(featured[0].node.featuredDate).getMonth() + 1
    const year = new Date(featured[0].node.featuredDate).getFullYear()
  }

  useEffect(() => {
    featured.length > 0 && setFeaturedTribute(featured[0].node)
  }, [])

  return (
    <Layout transparentHeader>
      <SEO title={ogtitle} description={ogdescription} />
      <Banner data={bannerData} noStroke={featuredTribute ? true : false} />
      {!!featuredTribute && <Featured data={featuredTribute} />}
      <Tributes data={tributesSectionData} />
      <Articles data={articleSectionData} />
    </Layout>
  )
}

export const query = graphql`
  query HomepageQuery {
    home: contentfulHomePage {
      ogtitle
      ogdescription
      title {
        raw
      }
      details
      bannerImage {
        title
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      tributeSectionTitle
      tributeSectionDetails
      articlesSectionTitle {
        raw
      }
      articlesList {
        title
        imageUrl
        articleLink
        description {
          description
        }
        category
      }
    }

    featuredTributes: allContentfulTributes(
      filter: { featured: { eq: true } }
    ) {
      edges {
        node {
          id
          featured
          featuredDate
          tributeContent {
            tributeContent
          }
          mom {
            firstName
            lastName
            birthState
            birthCity
            birthDate(formatString: "MMM DD")
            image {
              title
              fluid(quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }

    tributes: allContentfulTributes(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          id
          createdAt
          location
          alive
          longitude
          latitude
          sliderContent {
            title
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          mom {
            firstName
            lastName
            birthState
            birthCity
            birthDate(fromNow: true)
            image {
              title
              fluid(quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
